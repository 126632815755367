<template>
    <div>
        <b class="p-2 w-100 d-inline-block bg-white pb-1" 
            v-if="props.caption" 
            @click="collapsed = !collapsed" 
            data-bs-toggle="collapse" 
            :data-bs-target="`#collapse-${sanitizeForId(props.caption)}`" 
            style="cursor: pointer;font-size: 110%;"
        >
            <span>{{props.caption}}</span>
        </b>
        <div class="border-top collapse" :class="{'show':!props.collapsed}" :id="`collapse-${sanitizeForId(props.caption)}`">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue';

    const collapsed = ref(false);

    const props = defineProps({
        caption:String,
        collapsed:{
            type:Boolean,
            required:false,
            default:false
        }
    });

    onMounted(() => {
        collapsed.value=props.collapsed
        console.log(`Collapsed ${collapsed.value}`)
    })

    function sanitizeForId(inputString) {
        const sanitizedString = inputString.replace(/[^a-zA-Z0-9-_:.]/g, '');
        return sanitizedString;
    }

</script>